<div class="chatbox-navback"><i class="fa fa-arrow-left"></i></div>
<div class="chatbox-title">
    <div class="chat-title" {[ if (o._converse.locked_muc_domain !== 'hidden') { ]} title="{{{o.jid}}}" {[ } ]} >
        {{{ o.title }}}
    </div>
    <!-- Sanitized in converse-muc-views. We want to render links. -->
    <p class="chatroom-description">{{o.description}}</p>
</div>
<div class="chatbox-buttons row no-gutters">
    {[ if (!o._converse.singleton) { ]}
        <a class="chatbox-btn close-chatbox-button fa fa-sign-out-alt" title="{{{o.info_close}}}"></a>
    {[ } ]}
    {[ if (o.isOwner) { ]}
        <a class="chatbox-btn configure-chatroom-button fa fa-wrench" title="{{{o.info_configure}}} "></a>
    {[ } ]}
    <a class="chatbox-btn show-room-details-modal fa fa-info-circle" title="{{{o.info_details}}}"></a>
</div>
