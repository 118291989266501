<div class="chat-head chat-head-chatbox row no-gutters">
    <div class="chatbox-navback"><i class="fa fa-arrow-left"></i></div>
    <div class="chatbox-title">
        <div class="row no-gutters">
            {[ if (o.type !== o._converse.HEADLINES_TYPE) { ]}
                <canvas class="avatar" height="36" width="36"></canvas>
            {[ } ]}
            <div class="col chat-title" title="{{{o.jid}}}">
                {[ if (o.url) { ]}
                    <a href="{{{o.url}}}" target="_blank" rel="noopener" class="user">
                {[ } ]}
                        {{{ o.display_name }}}
                {[ if (o.url) { ]}
                    </a>
                {[ } ]}
                <p class="user-custom-message">{{{ o.status }}}</p>
            </div>
        </div>
    </div>
    <div class="chatbox-buttons row no-gutters">
        <a class="chatbox-btn close-chatbox-button fa fa-times" title="{{{o.info_close}}}"></a>
        <a class="chatbox-btn show-user-details-modal fa fa-id-card" title="{{{o.info_details}}}"></a>
    </div>
</div>
