{[ if (o.use_emoji)  { ]}
<li class="toggle-toolbar-menu toggle-smiley dropup">
    <a class="toggle-smiley far fa-smile" title="{{{o.tooltip_insert_smiley}}}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a> 
    <div class="emoji-picker dropdown-menu toolbar-menu"></div>
</li>
{[ } ]}
{[ if (o.show_call_button)  { ]}
<li class="toggle-call fa fa-phone" title="{{{o.label_start_call}}}"></li>
{[ } ]}
{[ if (o.show_occupants_toggle)  { ]}
<li class="toggle-occupants float-right fa {[ if (o.hidden_occupants)  { ]} fa-angle-double-left {[ } else { ]} fa-angle-double-right {[ } ]}"
    title="{{{o.label_hide_occupants}}}"></li>
{[ } ]}
{[ if (o.message_limit)  { ]}
<li class="message-limit font-weight-bold float-right" title="{{{o.label_message_limit}}}">{{{o.message_limit}}}</li>
{[ } ]}
